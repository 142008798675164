
function setCookie(cookieName,cookieValue) {
  var name =cookieName;
  var value=cookieValue;
  let days=30;
  var domain, domainParts, date, expires, host;

      if (days)
      {
         date = new Date();
         date.setTime(date.getTime()+(days*24*60*60*1000));
         expires = "; expires="+date.toGMTString();
      }
      else
      {
         expires = "";
      }
      host = location.host; //"weblogin-uat.5paisa.com";
      // if (host.split('.').length === 1)
      // { 
      //    document.cookie = name+"="+value+expires+";Secure; path=/";
      // }
      // else
      // { 
         document.cookie = name+"="+value+expires+";Secure; path=/;";
         
         domainParts = host.split('.');
         domainParts.shift();
         domain = '.'+domainParts.join('.');
         //document.cookie = name+"="+value+expires+"; path=/ HttpOnly; Secure; SameSite=Lax; domain="+domain;
         document.cookie = name+"="+value+expires+";Secure; path=/; domain="+domain;
        
      //}
}


